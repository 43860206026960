/* eslint-disable react/state-in-constructor */
/* eslint react/no-unused-state: 0 */
import React, { Component } from 'react';

export const LanguageContext = React.createContext();

export default class LanguageContextProvider extends Component {
  constructor(props) {
    super(props);
    let browserLang;
    if (typeof window !== 'undefined') browserLang = window?.navigator?.language?.slice(0, 2).toLowerCase();
    this.state = {
      language: browserLang === 'it' ? 'it' : 'en',
      toggleLanguage: () => {
        this.setState({
          language: this.state.language === 'en' ? 'it' : 'en',
        });
      },
    };
  }

  render() {
    return (
      <LanguageContext.Provider value={this.state}>
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}
